import _ from 'lodash';

function auth (username, password, otptoken, resolve, reject, complete) {
  console.log('users.auth ...');

  const data = { username, password, otptoken };

  this.$api.SSP.post('/auth/authenticate', new URLSearchParams(data), {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
  .then(response => {
    console.log('users.auth => ', response);
    const data = response.data;
    const status = response.status;
    resolve(data, status);
  })
  .catch(error => {
    this.handleError(error);
    if (this.isValid(reject)) reject(error);
  })
  .finally(() => {
    // callback function
    if (this.isValid(complete)) complete();
  });
}

function get (token, resolve, reject, complete) {
  console.log('users.get ...');
  
  this.$api.SSP.get('/users/', {
    headers: {
      'Authorization': `Bearer ${token}`,
    }
  })
  .then(response => {
    console.log('users.get => ', response);
    let notification = response.data.notification;
    notification = _.isNil(notification) ? 1 : notification;
    const data = { ...response.data, notification }
    resolve(data);
  })
  .catch(error => {
    this.handleError(error);
    if (this.isValid(reject)) reject(error);
  })
  .finally(() => {
    // callback function
    if (this.isValid(complete)) complete();
  });
}

function ghost (token, id, resolve, reject, complete) {
  console.log('users.ghost ...');
  
  this.$api.SSP.post('/users/ghost', new URLSearchParams({ id }),  {
    headers: {
      'Authorization': `Bearer ${token}`,
    }
  })
  .then(response => {
    console.log('users.ghost => ', response);
    let notification = response.data.notification;
    notification = _.isNil(notification) ? 1 : 0;
    const data = { ...response.data, notification }
    resolve(data);
  })
  .catch(error => {
    this.handleError(error);
    if (this.isValid(reject)) reject(error);
  })
  .finally(() => {
    // callback function
    if (this.isValid(complete)) complete();
  });
}

function update (token, user_id, data, resolve, reject, complete) {
  console.log('users.update ...');

  data = { user_id, ...data };

  this.$api.SSP.put('/users/', new URLSearchParams(data), {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
  .then(response => {
    console.log('users.update => ', response);
    resolve(response.data);
  })
  .catch(error => {
    this.handleError(error);
    if (this.isValid(reject)) reject(error);
  })
  .finally(() => {
    // callback function
    if (this.isValid(complete)) complete();
  });
}

function createPassword (username, password, token, resolve, reject, complete) {
  console.log('users.createPassword ...');

  const data = { username, password, token };

  this.$api.SSP.put('/users/password', new URLSearchParams(data), {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
  .then(response => {
    console.log('users.createPassword => ', response);
    resolve(response);
  })
  .catch(error => {
    this.handleError(error);
    if (this.isValid(reject)) reject(error);
  })
  .finally(() => {
    // callback function
    if (this.isValid(complete)) complete();
  });
}

function resetPassword (username, resolve, reject, complete) {
  console.log('users.resetPassword ...');

  const params = new URLSearchParams([['username', username]]);
  
  this.$api.SSP.get('/users/password', { params })
  .then(response => {
    console.log('users.resetPassword => ', response);
    resolve();
  })
  .catch(error => {
    this.handleError(error);
    if (this.isValid(reject)) reject(error);
  })
  .finally(() => {
    // callback function
    if (this.isValid(complete)) complete();
  });
}

export {
  auth,
  createPassword,
  resetPassword,
  get,
  ghost,
  update
}
